import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  Application,
  ApplicationDocumentResponse,
  ApplicationsListResponse,
  HTTPError
} from "../../../types";
import { CommonDataApi } from "../../..";

type Variables = {
  previousApplication: Application;
  application: Partial<Application>;
};

/**
 * Hook to update an application
 */
export const useUpdateApplication = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<ApplicationDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ previousApplication, application }: Variables) =>
      CommonDataApi.Applications.update(previousApplication, application),
    onSuccess: (node) => {
      queryClient.setQueryData<ApplicationsListResponse | undefined>(
        ["applications", "list"],
        (currentState: ApplicationsListResponse | undefined) => {
          if (!currentState) return undefined;

          currentState.data = currentState.data.map((state) =>
            state.applicationId === node.data.applicationId ? node.data : state
          );

          return {
            ...currentState,
            data: [...currentState.data]
          };
        }
      );

      queryClient.invalidateQueries(["applications", "single"]);
    }
  };

  const { mutateAsync, isLoading, error, data } = useMutation(queryOptions);

  return {
    application: data,
    error: error,
    loading: isLoading,
    updateApplication: mutateAsync
  };
};
