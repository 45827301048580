import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { CommonDataApi } from "../../..";
import {
  HTTPError,
  Location,
  LocationDocumentResponse,
  LocationListResponse
} from "../../../types";
import { locationsOfSite } from "./query-cache";

/**
 * Hook to create a new location
 */
export const useCreateLocation = (applicationId: string, siteId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<LocationDocumentResponse, HTTPError, Location> = {
    mutationFn: (location: Location) =>
      CommonDataApi.Locations.create(applicationId, siteId, location),
    onSuccess: (application) => {
      queryClient.setQueryData<LocationListResponse | undefined>(
        locationsOfSite(applicationId, siteId),
        (currentState: LocationListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [...currentState.data, application.data]
          };
        }
      );
    }
  };
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    application: data,
    createLocation: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
