import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  HTTPError,
  Location,
  LocationDocumentResponse,
  LocationListResponse
} from "../../../types";
import { CommonDataApi } from "../../..";
import { locationById, locationsOfSite } from "./query-cache";

type Variables = {
  previousLocation: Location;
  location: Partial<Location>;
};

/**
 * Update a location
 */
export const useUpdateLocation = (applicationId: string, siteId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<LocationDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ previousLocation, location }: Variables) =>
      CommonDataApi.Locations.update(applicationId, siteId, previousLocation, location),
    onSuccess: (node) => {
      queryClient.setQueryData<LocationListResponse | undefined>(
        locationsOfSite(applicationId, siteId),
        (currentState: LocationListResponse | undefined) => {
          if (!currentState) return undefined;

          currentState.data = currentState.data.map((state) =>
            state.locationId === node.data.locationId ? node.data : state
          );

          return {
            ...currentState,
            data: [...currentState.data]
          };
        }
      );
      queryClient.setQueryData(locationById(node.data.locationId), () => node);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess, isError } = useMutation(queryOptions);

  return {
    location: data,
    error: error,
    loading: isLoading,
    isSuccess,
    isError,
    updateLocation: mutateAsync
  };
};
