import { UseQueryOptions, useQuery } from "react-query";

import { ApplicationDocumentResponse, HTTPError } from "../../../types";
import { CommonDataApi } from "../../..";
import { applicationById } from "./query-cache";

/**
 * Hook to get a single application by ID
 */
export const useGetApplicationById = (applicationId: string) => {
  const queryOptions: UseQueryOptions<ApplicationDocumentResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Applications.getOne(applicationId),
    queryKey: applicationById(applicationId),
    enabled: !!applicationId
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(queryOptions);

  return {
    application: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
