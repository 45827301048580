import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi } from "../../..";
import { HTTPError, LocationListResponse } from "../../../types";
import { locationsOfSite } from "./query-cache";

/**
 * Hook to delete a location
 */
export const useDeleteLocation = (applicationId: string, siteId: string, locationId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<void, HTTPError> = {
    mutationFn: () => CommonDataApi.Locations.delete(applicationId, siteId, locationId),
    onSuccess: () => {
      queryClient.setQueryData<LocationListResponse | undefined>(
        locationsOfSite(applicationId, siteId),
        (currentState: LocationListResponse | undefined) => {
          if (!currentState) return undefined;

          // Delete
          const nextApplications = currentState.data.filter((state) => {
            return state.locationId !== locationId;
          });

          return {
            ...currentState,
            data: [...nextApplications]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    application: data,
    loading: isLoading,
    isError,
    isSuccess,
    deleteLocation: mutateAsync
  };
};
