import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi, HTTPError } from "../../..";
import { locationsList } from "./query-cache";

type Variables = {
  locationIds: string[];
  selectedLocationId: string;
};

/**
 * Hook to create a reorder a list of locations.
 */
export const useChangeLocationOrder = (applicationId: string, siteId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<void, HTTPError, Variables> = {
    mutationFn: ({ selectedLocationId, locationIds }: Variables) =>
      CommonDataApi.Locations.changeOrder(applicationId, siteId, selectedLocationId, locationIds),
    onSuccess: () => {
      queryClient.invalidateQueries(locationsList());
    }
  };
  const { mutateAsync, isLoading, error, data, isSuccess, isError } = useMutation(queryOptions);

  return {
    location: data,
    error: error,
    loading: isLoading,
    isSuccess,
    isError,
    changeLocationOrder: mutateAsync
  };
};
