import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  CommonDataApi,
  HTTPError,
  ApplicationsListResponse,
  CreateApplicationBody,
  ApplicationDocumentResponse
} from "../../..";

import { applicationsList } from "./query-cache";

/**
 * Hook to create a new application
 */
export const useCreateApplication = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    ApplicationDocumentResponse,
    HTTPError,
    CreateApplicationBody
  > = {
    mutationFn: (application) => CommonDataApi.Applications.create(application),
    onSuccess: (application) => {
      queryClient.setQueryData<ApplicationsListResponse | undefined>(
        applicationsList(),
        (currentState: ApplicationsListResponse) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [...currentState.data, application.data]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    application: data,
    createApplication: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
