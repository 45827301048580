import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";

const LandingPage = SpinningLoader(lazy(() => import("./pages/LandingPage")));
const Sites = SpinningLoader(lazy(() => import("./pages/Sites/index")));
const Shifts = SpinningLoader(lazy(() => import("./pages/Shifts")));
const Applications = SpinningLoader(lazy(() => import("./pages/Applications")));
const EditUser = SpinningLoader(lazy(() => import("./drawers/users/EditUser")));

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="applications/*" element={<Applications />} />
        <Route path="sites/*" element={<Sites />} />
        <Route path="users" element={<LandingPage />}>
          <Route path=":id/edit" element={<EditUser />} />
        </Route>
        <Route path="shifts/*" element={<Shifts />} />

        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="/users" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
