import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import MessageBus from "@packages/message-bus";
import { errorBoundary } from "@packages/theme-mui-v5";

import CommonDataAdmin from "./CommonDataAdmin";
import applicationConfig from "./config";

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: CommonDataAdmin,
  errorBoundary
});

export async function mount(props) {
  MessageBus.publish("app.load", applicationConfig);
  MessageBus.publish("app.navbar.title", { title: applicationConfig.displayName });
  return lifeCycles.mount(props);
}

export async function unmount(props) {
  MessageBus.publish("app.unload", {});
  return lifeCycles.unmount(props);
}

export const { bootstrap } = lifeCycles;

System.register(applicationConfig.name, [], function () {
  return {
    execute: () => ({ bootstrap, mount, unmount })
  };
});
